import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Accordion({ title, content }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div
      className="w-full mx-auto px-5"
      data-aos="fade-up"
      data-aos-duration="900"
      data-aos-once="true"
    >
      <div>
        <div
          className="flex justify-between items-center px-[1.5em] py-[0.93em] cursor-pointer rounded-[100px]"
          onClick={toggleAccordion}
          style={{
            boxShadow: "rgba(78, 81, 144, 0.13) 20px 20px 50px 20px",
          }}
        >
          <p
            className="text-[18px] font-medium leading-[2em] tracking-[-1px] text-[#000024] text-start"
            style={{ fontFamily: "Tajawal, Sans-serif" }}
          >
            {title}
          </p>
          <svg
            className={`w-6 h-6 ${isOpen ? "transform rotate-180" : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}
            />
          </svg>
        </div>
        <div
          className={`flex flex-col px-[30px] overflow-hidden text-start gap-4 ${isOpen ? " max-h-[9999px] py-[16px]" : "max-h-0"
            }`}
          style={{
            transition: "all 0.7s",
            transitionBehavior: "normal",
            transitionDuration: "0.7s",
            transitionTimingFunction: "ease",
            transitionDelay: "0s",
            transitionProperty: "all",
          }}
        >
          {content.map((item, index) => (
            <p
              key={index}
              className={`text-[16px] font-medium leading-[1.5em] tracking-[-0.8px] text-[#000024] ${index % 2 === 0 ? "text-[18px] font-semibold" : ""
                }`}
              style={{ fontFamily: "Tajawal, Sans-serif" }}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Accordion;
